export default ({ color }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 19" fill="none">
      <path
        d="M1.5 8.41829C1.5 5.47442 2.82149 2.84135 4.90264 1.08301H17.1802C19.2616 2.84137 20.5833 5.47446 20.5833 8.41829C20.5833 13.7125 16.308 17.9997 11.0412 17.9997C5.77453 17.9997 1.5 13.7126 1.5 8.41829Z"
        stroke={color ?? "#FB9347"}
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M11.0922 7.95251C10.4835 7.95251 9.98819 8.42425 9.98819 9.0041C9.98819 9.58394 10.4835 10.0557 11.0922 10.0557C11.701 10.0557 12.1962 9.58394 12.1962 9.0041C12.1962 8.42425 11.701 7.95251 11.0922 7.95251ZM11.0922 7.05664C12.1941 7.05664 13.0874 7.92855 13.0874 9.0041C13.0874 10.0796 12.1941 10.9516 11.0922 10.9516C9.99031 10.9516 9.09705 10.0796 9.09705 9.0041C9.09705 7.92855 9.99031 7.05664 11.0922 7.05664Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M13.3093 11.847C12.7005 11.847 12.2052 12.3188 12.2052 12.8986C12.2052 13.4785 12.7005 13.9502 13.3093 13.9502C13.918 13.9502 14.4133 13.4785 14.4133 12.8986C14.4133 12.3188 13.918 11.847 13.3093 11.847ZM13.3093 10.9512C14.4112 10.9512 15.3044 11.8231 15.3044 12.8986C15.3044 13.9742 14.4112 14.8461 13.3093 14.8461C12.2074 14.8461 11.3141 13.9742 11.3141 12.8986C11.3141 11.8231 12.2074 10.9512 13.3093 10.9512Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M6.65875 7.95251C6.04998 7.95251 5.55472 8.42425 5.55472 9.0041C5.55472 9.58394 6.04998 10.0557 6.65875 10.0557C7.26751 10.0557 7.76277 9.58394 7.76277 9.0041C7.76277 8.42425 7.26751 7.95251 6.65875 7.95251ZM6.65875 7.05664C7.76065 7.05664 8.65392 7.92855 8.65392 9.0041C8.65392 10.0796 7.76065 10.9516 6.65875 10.9516C5.55684 10.9516 4.66357 10.0796 4.66357 9.0041C4.66357 7.92855 5.55684 7.05664 6.65875 7.05664Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M8.87566 11.847C8.2669 11.847 7.77164 12.3188 7.77164 12.8986C7.77164 13.4785 8.2669 13.9502 8.87566 13.9502C9.48442 13.9502 9.97969 13.4785 9.97969 12.8986C9.97969 12.3188 9.48442 11.847 8.87566 11.847ZM8.87566 10.9512C9.97757 10.9512 10.8708 11.8231 10.8708 12.8986C10.8708 13.9742 9.97757 14.8461 8.87566 14.8461C7.77376 14.8461 6.88049 13.9742 6.88049 12.8986C6.88049 11.8231 7.77376 10.9512 8.87566 10.9512Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M8.83042 4.92224C8.49101 4.92224 8.21488 5.19984 8.21488 5.54105C8.21488 5.88226 8.49101 6.15985 8.83042 6.15985C9.16983 6.15985 9.44596 5.88226 9.44596 5.54105C9.44596 5.19984 9.16983 4.92224 8.83042 4.92224ZM8.83042 4.02637C9.66254 4.02637 10.3371 4.70452 10.3371 5.54105C10.3371 6.37758 9.66254 7.05573 8.83042 7.05573C7.9983 7.05573 7.32373 6.37758 7.32373 5.54105C7.32373 4.70452 7.9983 4.02637 8.83042 4.02637Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M13.264 4.92224C12.9246 4.92224 12.6485 5.19984 12.6485 5.54105C12.6485 5.88226 12.9246 6.15985 13.264 6.15985C13.6034 6.15985 13.8796 5.88226 13.8796 5.54105C13.8796 5.19984 13.6034 4.92224 13.264 4.92224ZM13.264 4.02637C14.0961 4.02637 14.7707 4.70452 14.7707 5.54105C14.7707 6.37758 14.0961 7.05573 13.264 7.05573C12.4319 7.05573 11.7573 6.37758 11.7573 5.54105C11.7573 4.70452 12.4319 4.02637 13.264 4.02637Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M15.5263 7.95251C14.9175 7.95251 14.4223 8.42425 14.4223 9.0041C14.4223 9.58394 14.9175 10.0557 15.5263 10.0557C16.1351 10.0557 16.6303 9.58394 16.6303 9.0041C16.6303 8.42425 16.1351 7.95251 15.5263 7.95251ZM15.5263 7.05664C16.6282 7.05664 17.5215 7.92855 17.5215 9.0041C17.5215 10.0796 16.6282 10.9516 15.5263 10.9516C14.4244 10.9516 13.5311 10.0796 13.5311 9.0041C13.5311 7.92855 14.4244 7.05664 15.5263 7.05664Z"
        fill={color ?? "#FB9347"}
      />
    </svg>
  );
};
