export default ({ color }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" fill="none">
      <path
        d="M17.4993 0.416992C8.10352 0.416992 0.416016 8.10449 0.416016 17.5003C0.416016 26.8962 8.10352 34.5837 17.4993 34.5837C26.8952 34.5837 34.5827 26.8962 34.5827 17.5003C34.5827 8.10449 26.8952 0.416992 17.4993 0.416992ZM17.4993 4.21329C20.4415 4.21329 23.1938 5.25727 25.4716 6.8707L6.86972 25.4725C5.25629 23.1948 4.21231 20.4425 4.21231 17.5003C4.21231 10.1925 10.1915 4.21329 17.4993 4.21329ZM17.4993 30.7874C14.5572 30.7874 11.8049 29.7434 9.52713 28.13L28.129 9.5281C29.7424 11.8059 30.7864 14.5582 30.7864 17.5003C30.7864 24.8082 24.8072 30.7874 17.4993 30.7874Z"
        fill={color ?? "#FFFF00"}
      />
    </svg>
  );
};
