import { useCallback } from "react";
import Scrollbars, { ScrollbarProps } from "react-custom-scrollbars-2";

export default ({ ...props }: ScrollbarProps) => {
  const renderThumb = useCallback(({ style, ...props }: any) => {
    return (
      <div
        style={{
          ...style,
          background: "#B4B4B4",
          borderRadius: "inherit",
          cursor: "pointer",
        }}
        {...props}
      />
    );
  }, []);

  return (
    <Scrollbars
      autoHeight
      autoHeightMin="100%"
      autoHeightMax="100%"
      style={{ width: "100%" }}
      renderThumbVertical={renderThumb}
      renderThumbHorizontal={renderThumb}
      {...props}
    />
  );
};
