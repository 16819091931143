const Logout = ({ color }: { color?: string }) => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.875 22.7034C18.7671 25.4042 16.5169 27.6967 13.5025 27.6238C12.801 27.6063 11.9333 27.3613 10.1994 26.8727C6.02708 25.6959 2.40458 23.7169 1.53542 19.285C1.375 18.4713 1.375 17.5554 1.375 15.7209V13.2796C1.375 11.4465 1.375 10.5292 1.53542 9.71399C2.40458 5.28357 6.02708 3.30461 10.1994 2.12773C11.9348 1.63919 12.801 1.39419 13.5025 1.37669C16.5169 1.30378 18.7671 3.59628 18.875 6.29711"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M27.6252 14.5003H11.5835M27.6252 14.5003C27.6252 13.4795 24.7172 11.572 23.9793 10.8545M27.6252 14.5003C27.6252 15.5212 24.7172 17.4287 23.9793 18.1462"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Logout;
