import { HttpClient, URL_API } from "../../network";

export type TLogin = {
  email: string;
  password: string;
};

class LoginServiceApi extends HttpClient {
  onLogin = async (dataUpdate: TLogin) => {
    const { data } = await this.instance.post(URL_API.AUTH.LOGIN, dataUpdate);
    return data;
  };

  getMe = async () => {
    const { data } = await this.instance.get(URL_API.AUTH.GET_ME);
    return data;
  };

  getOtp = async ({ email }: { email: string }) => {
    const { data } = await this.instance.post("/v1/admins/reset-password", {
      email,
    });
    return data;
  };

  changePassword = async (dto: {
    email: string;
    otp: string;
    password: string;
    confirm_password: string;
  }) => {
    const { data } = await this.instance.post(
      "/v1/admins/change-password",
      dto,
    );
    return data;
  };
}
export const LoginService = new LoginServiceApi();
