import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const StyledInput = styled(TextField)({
  border: "1px solid #ccc",
  borderRadius: "5px",
});

export const BlackSearchInput = styled(TextField)({
  border: "none",
  background: "black",
  borderRadius: "83px",
});
