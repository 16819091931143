import { Box, Button } from "@mui/material";
import { useState } from "react";
import {
  Controller,
  FieldError,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { ISchemaUpload, UploadService } from "../../services/Upload";

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  schema: ISchemaUpload;
  label?: string;
  error?: FieldError | undefined;
}

const UploadFileField = <T extends FieldValues>({
  schema,
  label,
  error,
  name,
  control,
}: Props<T>) => {
  const [loading, setLoading] = useState(false);

  return (
    <Box
      width={"100%"}
      display="flex"
      flexDirection={"column"}
      gap={1}
      border="1px solid #6f6c6c"
      padding={1}
      borderRadius={"5px"}
    >
      <Controller
        name={name}
        control={control}
        rules={{
          required: "This is required",
        }}
        render={({ field }) => {
          const { value, onChange, onBlur } = field;
          return (
            <>
              <Button variant="contained" component="label" fullWidth>
                {label ?? "Select File"}
                <input
                  type="file"
                  onBlur={onBlur}
                  hidden
                  onChange={async (e) => {
                    if (e.target.files?.[0])
                      try {
                        setLoading(true);
                        const result = await UploadService.upload({
                          file: e.target.files[0],
                          schema: schema,
                        });
                        if (result.ok) {
                          onChange(result.data.link);
                        }
                      } catch (error) {
                        console.log("error", error);
                      } finally {
                        setLoading(false);
                      }
                  }}
                />
              </Button>

              {loading ? (
                <Box textAlign={"center"}>Loading</Box>
              ) : (
                value && (
                  <Box textAlign={"center"}>
                    <img src={value} style={{ width: "50%" }} />
                  </Box>
                )
              )}
              {error && (
                <span
                  style={{
                    color: "red",
                    fontSize: "11px",
                    marginTop: "-10px",
                  }}
                >
                  {error?.message}
                </span>
              )}
            </>
          );
        }}
      />
    </Box>
  );
};

export default UploadFileField;
