export default ({ color }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 33" fill="none">
      <path
        d="M14.4972 1.9165C10.3409 1.9165 7.64736 4.86088 4.4609 5.93421C3.1659 6.37171 2.51694 6.589 2.2559 6.89671C1.9934 7.20296 1.91611 7.65359 1.76299 8.55192C0.119444 18.1711 3.71132 27.064 12.2761 30.5261C13.1963 30.898 13.6572 31.0832 14.5015 31.0832C15.3474 31.0832 15.8082 30.8965 16.7284 30.5246C25.2932 27.064 28.8807 18.1711 27.2372 8.55192C27.0826 7.65359 27.0067 7.20296 26.7442 6.89525C26.4817 6.589 25.8342 6.37025 24.5392 5.93421C21.3513 4.86088 18.6534 1.9165 14.4972 1.9165Z"
        stroke={color ?? "#FFFF00"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.2566 16.7435C15.7952 18.2821 17.8222 19.8454 17.8222 19.8454L20.6004 17.0673C20.6004 17.0673 19.037 15.0402 17.4985 13.5017C15.9585 11.9646 13.9314 10.3998 13.9314 10.3998L11.1547 13.1779C11.1547 13.1779 12.7181 15.205 14.2566 16.7435ZM14.2566 16.7435L9.396 21.6042M21.0627 16.6035L17.3585 20.3077M14.3981 9.9375L10.6939 13.6417"
        stroke={color ?? "#FFFF00"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
