export default ({ color }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 31" fill="none">
      <path
        d="M5.30372 2.34106C3.31665 3.94247 2.04288 6.41154 2.04288 9.18133C2.04288 14.0085 5.91115 17.9218 10.6829 17.9218C15.4546 17.9218 19.3236 14.0085 19.3236 9.18133C19.3236 6.41154 18.0496 3.94247 16.0623 2.34106H5.30372ZM5.30372 0.875H16.0623C16.3895 0.875 16.7071 0.987349 16.9632 1.19372C19.383 3.14364 20.7708 6.05502 20.7708 9.18133C20.7708 14.8093 16.2454 19.3879 10.6829 19.3879C5.12079 19.3879 0.595703 14.8093 0.595703 9.18133C0.595703 6.05498 1.98332 3.14363 4.40276 1.19376C4.65887 0.987368 4.97646 0.875 5.30372 0.875Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M5.71573 20.3892C3.32349 20.3892 1.37727 22.3609 1.37727 24.7843C1.37727 27.2078 3.32349 29.1794 5.71573 29.1794H15.6507C18.0429 29.1794 19.9891 27.2078 19.9891 24.7843C19.9891 22.3609 18.0429 20.3892 15.6507 20.3892H5.71573ZM5.71573 19.1187H15.6507C18.7394 19.1187 21.2433 21.6553 21.2433 24.7843C21.2433 27.9134 18.7394 30.45 15.6507 30.45H5.71573C2.62698 30.45 0.123047 27.9134 0.123047 24.7843C0.123047 21.6553 2.62698 19.1187 5.71573 19.1187Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M15.1486 22.7001C14.0142 22.7001 13.0912 23.6351 13.0912 24.7843C13.0912 25.9336 14.0142 26.8685 15.1486 26.8685C16.2831 26.8685 17.206 25.9336 17.206 24.7843C17.206 23.6351 16.2831 22.7001 15.1486 22.7001ZM15.1486 21.234C17.0841 21.234 18.6532 22.8235 18.6532 24.7843C18.6532 26.7451 17.0841 28.3346 15.1486 28.3346C13.2131 28.3346 11.644 26.7451 11.644 24.7843C11.644 22.8235 13.2131 21.234 15.1486 21.234Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M6.07878 22.7001C4.94433 22.7001 4.02139 23.6351 4.02139 24.7843C4.02139 25.9336 4.94433 26.8685 6.07878 26.8685C7.21323 26.8685 8.13617 25.9336 8.13617 24.7843C8.13617 23.6351 7.21323 22.7001 6.07878 22.7001ZM6.07878 21.234C8.0143 21.234 9.58334 22.8235 9.58334 24.7843C9.58334 26.7451 8.0143 28.3346 6.07878 28.3346C4.14326 28.3346 2.57422 26.7451 2.57422 24.7843C2.57422 22.8235 4.14326 21.234 6.07878 21.234Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M10.683 8.03969C10.0239 8.03969 9.48773 8.55435 9.48773 9.18694C9.48773 9.81954 10.0239 10.3342 10.683 10.3342C11.342 10.3342 11.8782 9.81954 11.8782 9.18694C11.8782 8.55435 11.342 8.03969 10.683 8.03969ZM10.683 7.06232C11.8759 7.06232 12.843 8.01355 12.843 9.18694C12.843 10.3603 11.8759 11.3116 10.683 11.3116C9.49003 11.3116 8.52295 10.3603 8.52295 9.18694C8.52295 8.01355 9.49003 7.06232 10.683 7.06232Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M13.0834 12.2888C12.4243 12.2888 11.8881 12.8035 11.8881 13.4361C11.8881 14.0687 12.4243 14.5833 13.0834 14.5833C13.7424 14.5833 14.2786 14.0687 14.2786 13.4361C14.2786 12.8035 13.7424 12.2888 13.0834 12.2888ZM13.0834 11.3115C14.2763 11.3115 15.2434 12.2627 15.2434 13.4361C15.2434 14.6095 14.2763 15.5607 13.0834 15.5607C11.8904 15.5607 10.9233 14.6095 10.9233 13.4361C10.9233 12.2627 11.8904 11.3115 13.0834 11.3115Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M5.88318 8.03969C5.22412 8.03969 4.68793 8.55435 4.68793 9.18694C4.68793 9.81954 5.22412 10.3342 5.88318 10.3342C6.54224 10.3342 7.07843 9.81954 7.07843 9.18694C7.07843 8.55435 6.54224 8.03969 5.88318 8.03969ZM5.88318 7.06232C7.07613 7.06232 8.04321 8.01355 8.04321 9.18694C8.04321 10.3603 7.07613 11.3116 5.88318 11.3116C4.69022 11.3116 3.72314 10.3603 3.72314 9.18694C3.72314 8.01355 4.69022 7.06232 5.88318 7.06232Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M8.28357 12.2888C7.62451 12.2888 7.08832 12.8035 7.08832 13.4361C7.08832 14.0687 7.62451 14.5833 8.28357 14.5833C8.94263 14.5833 9.47882 14.0687 9.47882 13.4361C9.47882 12.8035 8.94263 12.2888 8.28357 12.2888ZM8.28357 11.3115C9.47652 11.3115 10.4436 12.2627 10.4436 13.4361C10.4436 14.6095 9.47652 15.5607 8.28357 15.5607C7.09061 15.5607 6.12354 14.6095 6.12354 13.4361C6.12354 12.2627 7.09061 11.3115 8.28357 11.3115Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M8.23421 4.7347C7.86676 4.7347 7.56781 5.03755 7.56781 5.4098C7.56781 5.78205 7.86676 6.0849 8.23421 6.0849C8.60167 6.0849 8.90062 5.78205 8.90062 5.4098C8.90062 5.03755 8.60167 4.7347 8.23421 4.7347ZM8.23421 3.75732C9.13509 3.75732 9.8654 4.49717 9.8654 5.4098C9.8654 6.32243 9.13509 7.06227 8.23421 7.06227C7.33334 7.06227 6.60303 6.32243 6.60303 5.4098C6.60303 4.49717 7.33334 3.75732 8.23421 3.75732Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M13.0345 4.7347C12.6671 4.7347 12.3681 5.03755 12.3681 5.4098C12.3681 5.78205 12.6671 6.0849 13.0345 6.0849C13.402 6.0849 13.7009 5.78205 13.7009 5.4098C13.7009 5.03755 13.402 4.7347 13.0345 4.7347ZM13.0345 3.75732C13.9354 3.75732 14.6657 4.49717 14.6657 5.4098C14.6657 6.32243 13.9354 7.06227 13.0345 7.06227C12.1336 7.06227 11.4033 6.32243 11.4033 5.4098C11.4033 4.49717 12.1336 3.75732 13.0345 3.75732Z"
        fill={color ?? "#FB9347"}
      />
      <path
        d="M15.4838 8.03969C14.8247 8.03969 14.2885 8.55435 14.2885 9.18694C14.2885 9.81954 14.8247 10.3342 15.4838 10.3342C16.1428 10.3342 16.679 9.81954 16.679 9.18694C16.679 8.55435 16.1428 8.03969 15.4838 8.03969ZM15.4838 7.06232C16.6767 7.06232 17.6438 8.01355 17.6438 9.18694C17.6438 10.3603 16.6767 11.3116 15.4838 11.3116C14.2908 11.3116 13.3237 10.3603 13.3237 9.18694C13.3237 8.01355 14.2908 7.06232 15.4838 7.06232Z"
        fill={color ?? "#FB9347"}
      />
    </svg>
  );
};
