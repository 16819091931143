export default ({ color }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 165 165" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M120.214 53.6457C122.506 55.9284 122.514 59.6369 120.231 61.9289L70.8788 111.482C68.5962 113.774 64.8878 113.781 62.5958 111.499L44.5972 93.575C42.3051 91.2924 42.2974 87.5839 44.58 85.2918C46.8626 82.9997 50.5711 82.992 52.8632 85.2746L66.7118 99.0657L111.931 53.6625C114.214 51.3705 117.922 51.363 120.214 53.6457Z"
        fill={color ?? "#FFFF00"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.7778 17.2143C50.7305 17.2143 24.6841 38.8937 17.9981 67.9361L17.9981 67.9362C16.9097 72.6635 16.3334 77.5909 16.3334 82.6586C16.3334 118.775 45.6613 148.103 81.7778 148.103C117.894 148.103 147.222 118.775 147.222 82.6586C147.222 46.5421 117.894 17.2143 81.7778 17.2143ZM6.58245 65.308C14.4646 31.0697 45.1491 5.5 81.7778 5.5C124.364 5.5 158.936 40.0725 158.936 82.6586C158.936 125.245 124.364 159.817 81.7778 159.817C39.1917 159.817 4.61914 125.245 4.61914 82.6586C4.61914 76.6999 5.2971 70.8909 6.58245 65.308Z"
        fill={color ?? "#FFFF00"}
      />
    </svg>
  );
};
