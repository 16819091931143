import { Button, styled, ButtonProps, Typography } from "@mui/material";
import { ReactNode } from "react";

export const ButtonHeader = (
  props: ButtonProps & { title: string; icon?: ReactNode },
) => {
  return (
    <ButtonCustom startIcon={props.icon} {...props}>
      <Typography
        fontSize={"20px"}
        fontWeight="bold"
        sx={{ textDecoration: "underline" }}
      >
        {props.title}
      </Typography>
    </ButtonCustom>
  );
};

const ButtonCustom = styled(Button)({
  color: "#00A22D",
  fontWeight: "bold",
  textTransform: "none",
  ":hover": {
    background: "transparent",
  },
});
