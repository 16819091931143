import { HttpClient, URL_API } from "../../network";

class Evse extends HttpClient {
  getAll = async (params: TParams) => {
    const data = await this.instance.get<IResponse<Array<IEvse>>>(
      URL_API.EVBS.GET_LIST,
      {
        params,
      },
    );
    return data;
  };
  getDetail = async (id: string) => {
    const data = await this.instance.get<any>(URL_API.EVBS.GET_LIST + `/${id}`);
    return data;
  };

  update = async (id: string, params: Object) => {
    const data = await this.instance.put<any>(
      URL_API.EVBS.GET_LIST + `/${id}`,
      params,
    );
    return data;
  };

  listUser = async (params?: Object) => {
    const data = await this.instance.get<any>(
      URL_API.EVBS.GET_LIST + `/restricted-users`,
      { params },
    );
    return data;
  };

  addRestrictedUser = async (dto: {
    user_ids: string[];
    evse_ids: string[];
  }) => {
    const data = await this.instance.post(
      URL_API.EVBS.GET_LIST + `/add-restricted-user`,
      dto,
    );
    return data;
  };

  RemoveRestrictedUser = async (dto: {
    user_ids: string[];
    evse_ids: string[];
  }) => {
    const data = await this.instance.post(
      URL_API.EVBS.GET_LIST + `/remove-restricted-user`,
      dto,
    );
    return data;
  };

  UpdateRestrictedUser = async (dto: {
    user_id: string;
    evse_ids: string[];
  }) => {
    const data = await this.instance.post(
      URL_API.EVBS.GET_LIST + `/update-restricted-user`,
      dto,
    );
    return data;
  };

  OnOffSubscriptionPlans = async (dto: {
    evse_id: string;
    subscription_price_enabled: boolean;
    enable_subscription_plans: string[];
  }) => {
    const { evse_id, ...parm } = dto;
    const data = await this.instance.post(
      URL_API.EVBS.GET_LIST + `/${evse_id}/subscription-plans`,
      parm,
    );
    return data;
  };

  topUtilizationRating = async (params: TParams) => {
    const data = await this.instance.get<IResponse<Array<IEvse>>>(
      URL_API.EVBS.TOP_UTILIZATION_RATING,
      {
        params,
      },
    );
    return data;
  };
}
export const EvseService = new Evse();
