import { useMutation, useQuery } from "react-query";
import { LoginService, TLogin } from "./login.service";
import toast from "react-hot-toast";
import { setLocalItem } from "../../utils/localStorage";

const useLogin = (cb?: (data: any) => void) => {
  return useMutation(
    async (form: TLogin) => {
      const data = await LoginService.onLogin(form);
      return data;
    },
    {
      onSuccess: (data) => {
        if (data.ok) {
          window.localStorage.setItem("token", JSON.stringify(data?.token));
          // window.localStorage.setItem("profile", JSON.stringify(data?.user));
          // setLocalItem("token", data?.token);
          // setLocalItem("profile", data?.user);
          window.dispatchEvent(new Event("storage"));

          toast.success("Login successfully!");
          cb?.(data?.user);
        } else {
          toast.error(data?.msg);
        }
      },
      onError(error) {
        alert(error);
      },
    },
  );
};

const useInfoMe = (token?: string) => {
  return useQuery({
    queryKey: ["get_me", token],
    queryFn: async () => {
      try {
        const result = await LoginService.getMe();
        return result;
      } catch (error) {
        console.log("error", error);
      }
    },
    enabled: Boolean(token),
  });
};

export const useGetOtp = () =>
  useMutation({
    mutationFn: async ({ email }: { email: string }) => {
      try {
        const result = await LoginService.getOtp({ email });
        return result;
      } catch (error) {
        console.log("error", error);
      }
    },
  });

export const useChangePassword = () =>
  useMutation({
    mutationFn: async (dto: {
      email: string;
      otp: string;
      password: string;
      confirm_password: string;
    }) => {
      try {
        const result = await LoginService.changePassword(dto);
        return result;
      } catch (error) {
        console.log("error", error);
      }
    },
  });

export { useLogin, useInfoMe };
