export default ({ color }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27" fill="none">
      <path
        d="M6.01081 6.17414C7.92635 4.252 10.5734 3.06445 13.4999 3.06445C19.3392 3.06445 24.0728 7.79811 24.0728 13.6374C24.0728 19.4766 19.3392 24.2103 13.4999 24.2103C7.66066 24.2103 2.927 19.4766 2.927 13.6374C2.927 13.0333 2.43731 12.5436 1.83325 12.5436C1.22919 12.5436 0.739502 13.0333 0.739502 13.6374C0.739502 20.6848 6.45253 26.3978 13.4999 26.3978C20.5473 26.3978 26.2603 20.6848 26.2603 13.6374C26.2603 6.58999 20.5473 0.876953 13.4999 0.876953C9.96858 0.876953 6.77055 2.31286 4.46136 4.63C4.42424 4.66724 4.39037 4.70649 4.35974 4.74739L2.52578 2.91344C2.32572 2.71337 2.02799 2.6471 1.76196 2.74342C1.49593 2.83974 1.30963 3.08125 1.28401 3.36302L0.768412 9.03461C0.74882 9.25012 0.825966 9.4632 0.978985 9.61622C1.132 9.76924 1.34509 9.84639 1.5606 9.82679L7.23219 9.31119C7.51395 9.28558 7.75547 9.09928 7.85178 8.83325C7.9481 8.56722 7.88183 8.26948 7.68177 8.06942L5.89077 6.27842C5.93265 6.24705 5.97279 6.21229 6.01081 6.17414Z"
        fill={color ?? "#FFFF00"}
      />
      <path
        d="M14.5937 6.3457C14.5937 5.74164 14.104 5.25195 13.4999 5.25195C12.8959 5.25195 12.4062 5.74164 12.4062 6.3457V13.6374C12.4062 14.0145 12.6004 14.365 12.9202 14.5649L17.2952 17.2992C17.8075 17.6194 18.4823 17.4637 18.8024 16.9514C19.1226 16.4392 18.9668 15.7644 18.4546 15.4442L14.5937 13.0312V6.3457Z"
        fill={color ?? "#FFFF00"}
      />
    </svg>
  );
};
