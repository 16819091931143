import {
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Switch,
  TextField,
  InputAdornment,
} from "@mui/material";
import { IconApp, Modal } from "../../";
import SaveIcon from "@mui/icons-material/Save";
import { convertTimeToClient } from "../../../utils/convertTime";
import { useEffect, useState } from "react";
import { useUpdateEvse } from "../../../services/evse";

export const ModalIdlingPenalty = (props: {
  open: boolean;
  onClose: () => void;
  init?: IEvse;
  success: () => void;
}) => {
  const [evse, setEvse] = useState<Partial<IEvse>>({});

  useEffect(() => {
    setEvse({
      idle_timing_enabled: props.init?.idle_timing_enabled,
      idle_grace_period: props.init?.idle_grace_period,
      idle_timing_0: props.init?.idle_timing_0,
      idle_timing_1: props.init?.idle_timing_1,
      idle_timing_2: props.init?.idle_timing_2,
      idle_timing_3: props.init?.idle_timing_3,
      idle_timing_4: props.init?.idle_timing_4,
      idle_timing_5: props.init?.idle_timing_5,
      idle_timing_6: props.init?.idle_timing_6,
    });
  }, [props.open, props.init?._id]);

  const { mutateAsync } = useUpdateEvse();

  const handleUpdate = async () => {
    try {
      const result = await mutateAsync({
        id: props.init?._id!,
        dto: { ...evse },
      });
      if (result) {
        props.success();
        props.onClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Modal
      {...props}
      name="Idling penalty"
      description="View & edit your charge point’s pricing"
      icon={<IconApp width={"27px"} name={"IdLingPenalty"} />}
    >
      <Box display={"flex"} flexDirection="column" gap={3}>
        <Grid container bgcolor={"#1C1C1C"} borderRadius="12px" p={"18px 21px"}>
          <Grid item xs={3} borderRight="1px solid rgba(255, 255, 255, 0.12)">
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Model
            </Text>
            <TextName>{props.init?.model || ""}</TextName>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Type
            </Text>
            <Box display={"flex"} gap={2} alignItems="center">
              <IconApp
                name={evse?.current_type === "ac" ? "AcCharger" : "DcCharger"}
                width="25px"
              />
              <TextName>{props.init?.max_p} kW</TextName>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Location ID
            </Text>
            <TextName>{props.init?.Location?.name}</TextName>
          </Grid>
          <Grid item xs={3} pl={1}>
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Charge Point ID
            </Text>
            <TextName>{props.init?.ampeco_evse_id}</TextName>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Box>
              <Typography fontSize={"14px"}>Idling penalty function</Typography>
              <Typography fontSize={"10px"} color="#9B9B9B">
                (Toggle to activate or deactive function)
              </Typography>
            </Box>
            <Switch
              checked={evse?.idle_timing_enabled}
              onChange={(e) =>
                setEvse({ ...evse, idle_timing_enabled: e.target.checked })
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography fontSize={"14px"}>Grace Period</Typography>
              <Typography fontSize={"10px"} color="#9B9B9B">
                (Time allowance for late-comers)
              </Typography>
            </Box>
            <Box display={"flex"} gap={1} mt={1}>
              <BoxValue
                active={evse?.idle_grace_period === `15`}
                onClick={() => setEvse({ ...evse, idle_grace_period: "15" })}
              >
                <Typography fontSize={"20px"}>
                  15 <span>mins</span>
                </Typography>
              </BoxValue>
              <BoxValue
                active={evse?.idle_grace_period === "30"}
                onClick={() => setEvse({ ...evse, idle_grace_period: "30" })}
              >
                <Typography fontSize={"20px"}>
                  30 <span>mins</span>
                </Typography>
              </BoxValue>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection={"column"}
            justifyContent="space-between"
          >
            <Box display={"flex"} justifyContent="space-between">
              <Typography fontSize={"14px"}>
                Idling Penalty Fee (/min)
              </Typography>
              <Typography fontSize={"14px"}>
                S$ {props.init?.idle_grace_fee}
              </Typography>
            </Box>
            <TextField
              // size="small"
              fullWidth
              autoComplete="off"
              onChange={(e) =>
                setEvse({ ...evse, idle_grace_fee: +e.target.value })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">S$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Box>
          <Typography fontSize={"14px"}>
            Customise your idling penalty schedule
          </Typography>
          <Typography fontSize={"10px"} color="#9B9B9B">
            (Set the timing you wish to impose idling penalty)
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DayField
              day="Monday"
              value={evse?.idle_timing_0 || ""}
              onChange={(e) => setEvse({ ...evse, idle_timing_0: e })}
            />
          </Grid>
          <Grid item xs={6}>
            <DayField
              day="Tuesday"
              value={evse?.idle_timing_1 || ""}
              onChange={(e) => setEvse({ ...evse, idle_timing_1: e })}
            />
          </Grid>
          <Grid item xs={6}>
            <DayField
              day="Wednesday"
              value={evse?.idle_timing_2 || ""}
              onChange={(e) => setEvse({ ...evse, idle_timing_2: e })}
            />
          </Grid>
          <Grid item xs={6}>
            <DayField
              day="Thurday"
              value={evse?.idle_timing_3 || ""}
              onChange={(e) => setEvse({ ...evse, idle_timing_3: e })}
            />
          </Grid>
          <Grid item xs={6}>
            <DayField
              day="Friday"
              value={evse?.idle_timing_4 || ""}
              onChange={(e) => setEvse({ ...evse, idle_timing_4: e })}
            />
          </Grid>
          <Grid item xs={6}>
            <DayField
              day="Saturday"
              value={evse?.idle_timing_5 || ""}
              onChange={(e) => setEvse({ ...evse, idle_timing_5: e })}
            />
          </Grid>
          <Grid item xs={6}>
            <DayField
              day="Sunday"
              value={evse?.idle_timing_6 || ""}
              onChange={(e) => setEvse({ ...evse, idle_timing_6: e })}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SaveIcon fontSize="large" />}
              onClick={handleUpdate}
            >
              Save Change
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="inherit"
              onClick={props.onClose}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const Text = styled(Typography)({ color: "#9B9B9B" });

const DayField = ({
  day,
  value,
  onChange,
}: {
  day: string;
  value: string;
  onChange?: (e: string) => void;
}) => {
  const { from, to } = convertTimeToClient(value);

  const handleChangeTime = (type: "from" | "to", value: string) => {
    if (type === "from") {
      onChange?.(`${value}-${to}`);
    } else {
      onChange?.(`${from}-${value}`);
    }
  };

  return (
    <Box>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography fontSize={"14px"} mb={"10px"}>
          {day}
        </Typography>
        <Switch defaultChecked />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            onChange={(e) => handleChangeTime("from", e.target.value)}
            value={from}
            autoComplete="off"
            size="small"
            fullWidth
            type={"time"}
          />
          {/* <TimePicker */}
          {/*   value={moment()} */}
          {/*   slots={{ */}
          {/*     textField: (params) => ( */}
          {/*       <TextField */}
          {/*         autoComplete="off" */}
          {/*         size="small" */}
          {/*         {...params} */}
          {/*         fullWidth */}
          {/*       /> */}
          {/*     ), */}
          {/*   }} */}
          {/* /> */}
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={(e) => handleChangeTime("to", e.target.value)}
            value={to}
            autoComplete="off"
            size="small"
            fullWidth
            type={"time"}
          />
          {/* <TimePicker */}
          {/*   value={moment(to)} */}
          {/*   slots={{ */}
          {/*     textField: (params) => ( */}
          {/*       <TextField */}
          {/*         autoComplete="off" */}
          {/*         size="small" */}
          {/*         {...params} */}
          {/*         fullWidth */}
          {/*       /> */}
          {/*     ), */}
          {/*   }} */}
          {/* /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export const BoxValue = styled(Box)<{ active: boolean }>(({ active }) => ({
  width: "100%",
  border: `1px solid ${active ? "#FFFF00" : "#C7C7C7"}`,
  color: active ? "#FFFF00" : "unset",
  borderRadius: "4px",
  padding: "12px 20px",
  textAlign: "center",
  cursor: "pointer",
  span: {
    fontSize: "13px",
    color: active ? "#FFFF00" : "#9B9B9B",
  },
}));

const TextName = styled(Typography)({
  fontSize: "17px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
