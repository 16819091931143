export default ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="19"
      viewBox="0 0 30 19"
      fill="none"
    >
      <path
        d="M16.4665 4.6651C13.1792 4.54413 9.88668 4.61369 6.60543 4.8738L6.34592 4.89437C5.74237 4.94222 5.24037 5.37765 5.10772 5.96839C4.58733 8.28599 4.58752 10.6908 5.10789 13.0083C5.24013 13.5973 5.73969 14.0324 6.34166 14.0821L6.58129 14.1019C9.87056 14.3735 13.1721 14.4464 16.468 14.3204C17.0218 14.2993 17.4599 13.8442 17.4599 13.2899V5.69565C17.4599 5.14086 17.021 4.6855 16.4665 4.6651Z"
        fill={color ?? "#FB9347"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.0931 12.2498V6.74983C29.0931 5.4209 28.0158 4.34358 26.6868 4.34358H25.7931V4.26063C25.7931 2.51295 24.4485 1.05944 22.7061 0.923585L20.9619 0.787586C16.0776 0.406742 11.1711 0.406742 6.28675 0.787585L4.4338 0.932065C2.77054 1.06175 1.40944 2.30769 1.13361 3.95304C0.517965 7.62528 0.517965 11.3744 1.13361 15.0466C1.40944 16.692 2.77054 17.9379 4.4338 18.0676L6.28676 18.2121C11.1711 18.5929 16.0776 18.5929 20.9619 18.2121L22.7061 18.0761C24.4485 17.9402 25.7931 16.4867 25.7931 14.739V14.6561H26.6868C28.0158 14.6561 29.0931 13.5788 29.0931 12.2498ZM27.0306 6.74983V12.2498C27.0306 12.4397 26.8767 12.5936 26.6868 12.5936L24.7618 12.5936C24.1923 12.5936 23.7306 13.0553 23.7306 13.6248V14.739C23.7306 15.4098 23.2145 15.9677 22.5458 16.0198L20.8016 16.1558C16.024 16.5283 11.2247 16.5283 6.44709 16.1558L4.59413 16.0113C3.87524 15.9553 3.28694 15.4168 3.16772 14.7056C2.58993 11.2591 2.58993 7.74053 3.16772 4.29406C3.28694 3.5829 3.87524 3.04438 4.59413 2.98832L6.44709 2.84384C11.2247 2.47132 16.024 2.47132 20.8016 2.84384L22.5458 2.97984C23.2145 3.03199 23.7306 3.58985 23.7306 4.26063V5.37483C23.7306 5.94438 24.1923 6.40608 24.7618 6.40608H26.6868C26.8767 6.40608 27.0306 6.55999 27.0306 6.74983Z"
        fill={color ?? "#FB9347"}
      />
    </svg>
  );
};
