import { Box, styled } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { IconApp } from "../Icon";

type IOption = {
  key: number;
  label: string;
  value: {
    from_date: string;
    to_date: string;
  };
};

export const FilterTime = ({
  onSelect,
}: {
  onSelect: (arg: { from_date: string; to_date: string }) => void;
}) => {
  const OPTIONS: Array<IOption> = useMemo(
    () => [
      {
        key: 1,
        label: "Today",
        value: {
          from_date: moment().format("YYYY-MM-DD"),
          to_date: moment().format("YYYY-MM-DD"),
        },
      },
      {
        key: 2,
        label: "Last 7 days",
        value: {
          from_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
          to_date: moment().format("YYYY-MM-DD"),
        },
      },
      {
        key: 3,
        label: "Last 30 days",
        value: {
          from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
          to_date: moment().format("YYYY-MM-DD"),
        },
      },
      {
        key: 4,
        label: "Last 90 days",
        value: {
          from_date: moment().subtract(90, "days").format("YYYY-MM-DD"),
          to_date: moment().format("YYYY-MM-DD"),
        },
      },
    ],
    [],
  );

  const [active, setActive] = useState<number>(3);

  useEffect(() => {
    onSelect(OPTIONS[2].value);
  }, []);

  const handleSelect = (option: IOption) => {
    setActive(option.key);
    onSelect(option.value);
  };

  return (
    <Wrapper>
      <IconApp name="Timer" width={20} marginLeft="23px" />
      <Box display="flex" gap={"8px"} alignItems="center">
        {OPTIONS.map((item) => (
          <ButtonCustom
            key={item.key}
            active={active === item.key}
            onClick={() => handleSelect(item)}
          >
            {item.label}
          </ButtonCustom>
        ))}
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  background: "black",
  borderRadius: "83px",
  padding: "8px 13px",
  display: "flex",
  alignItems: "center",
  gap: "50px",
  width: "fit-content",

  alignSelf: "flex-end",
});

const ButtonCustom = styled(Box)<{ active: boolean }>(({ active }) => ({
  cursor: "pointer",
  minWidth: "120px",
  borderRadius: "47px",
  padding: "5px 11px",
  background: active ? "#FFFF00" : "transparent",
  border: "1px solid",
  borderColor: active ? "#FFFF00" : "#525252",
  fontWeight: "bold",
  color: active ? "black" : "#525252",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
