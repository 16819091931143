import { HttpClient, URL_API } from "../../network";

// type TSubscriptionsPlans = {};

class SubscriptionsPlansServiceApi extends HttpClient {
  getListSubscriptionsPlans = async (params: TParams) => {
    const data = await this.instance.get(URL_API.SUBSCRIPTION_PLANS.GET_LIST, {
      params,
    });
    return data;
  };

  getDetailSubscriptionsPlans = async (idSubscriptionsPlans: string) => {
    const { data } = await this.instance.get(
      URL_API.SUBSCRIPTION_PLANS.GET_DETAIL + `/${idSubscriptionsPlans}`
    );
    return data;
  };

  createSubscriptionsPlans = async (dataCreate: any) => {
    const { data } = await this.instance.post(
      URL_API.SUBSCRIPTION_PLANS.CREATE,
      dataCreate
    );
    return data;
  };

  createSubscriptionsSubscriber = async (dataCreate: any) => {
    const { data } = await this.instance.post(
      URL_API.SUBSCRIPTION_PLANS.CREATE_SUBSCRIBE,
      dataCreate
    );
    return data;
  };

  updateSubscriptionsPlans = async (dataUpdate: any) => {
    const { data } = await this.instance.put(
      URL_API.SUBSCRIPTION_PLANS.UPDATE + `${dataUpdate?._id}`,
      dataUpdate
    );
    return data;
  };

  deleteSubscriptionsPlans = async (idSubscriptionsPlans: string) => {
    const { data } = await this.instance.delete(
      URL_API.SUBSCRIPTION_PLANS.DELETE + `/${idSubscriptionsPlans}`
    );
    return data;
  };

  generateActivationCode = async (dataCreate: any) => {
    const { data } = await this.instance.post(
      URL_API.SUBSCRIPTION_PLANS.GENERATE_ACTIVATION_CODE,
      dataCreate
    );
    return data;
  };
}

export const SubscriptionsPlansService = new SubscriptionsPlansServiceApi();
