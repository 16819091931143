export default ({ color }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 15" fill="none">
      <path
        d="M7.375 5.9375C7.375 6.43478 7.17746 6.91169 6.82583 7.26333C6.47419 7.61496 5.99728 7.8125 5.5 7.8125C5.00272 7.8125 4.52581 7.61496 4.17417 7.26333C3.82254 6.91169 3.625 6.43478 3.625 5.9375C3.625 5.44022 3.82254 4.96331 4.17417 4.61167C4.52581 4.26004 5.00272 4.0625 5.5 4.0625C5.99728 4.0625 6.47419 4.26004 6.82583 4.61167C7.17746 4.96331 7.375 5.44022 7.375 5.9375Z"
        stroke={color ?? "#9B9B9B"}
        stroke-width="0.7"
      />
      <path
        d="M5.5 1.25C8.03688 1.25 10.1875 3.3925 10.1875 5.99187C10.1875 8.6325 8.00187 10.4856 5.98312 11.7456C5.83619 11.8303 5.66959 11.8749 5.5 11.8749C5.33041 11.8749 5.16381 11.8303 5.01688 11.7456C3.00188 10.4731 0.8125 8.64187 0.8125 5.99187C0.8125 3.3925 2.96312 1.25 5.5 1.25Z"
        stroke={color ?? "#9B9B9B"}
        stroke-width="0.7"
      />
      <path
        d="M9.25 12.5C9.25 13.1906 7.57125 13.75 5.5 13.75C3.42875 13.75 1.75 13.1906 1.75 12.5"
        stroke={color ?? "#9B9B9B"}
        stroke-width="0.7"
        stroke-linecap="round"
      />
    </svg>
  );
};
