import {
  Box,
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ColumnTitle, IconApp, Modal } from "../../";
import SaveIcon from "@mui/icons-material/Save";
import Scrollbars from "react-custom-scrollbars-2";
import { useState } from "react";

const OPTION = [
  {
    label: "Price adjust",
    key: "price_adjust",
  },
  {
    label: "Reservation",
    key: "reservation",
  },
  {
    label: "Idling penalty",
    key: "idling_penalty",
  },
  {
    label: "Restricted User",
    key: "restricted_user",
  },
];

export const ModalAuditLog = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const [option, setOption] = useState<
    "price_adjust" | "reservation" | "restricted_user" | "idling_penalty"
  >("price_adjust");

  return (
    <Modal
      {...props}
      name="Audit log"
      description="View & edit your charge point’s pricing"
      icon={<IconApp width={"27px"} name={"History"} />}
      contentStyle={{
        sx: {
          width: {
            lg: "50%",
            md: "70%",
            xs: "90%",
          },
          maxWidth: {
            lg: "900px",
          },
          p: {
            xs: "30px",
          },
          pt: {
            xs: "28px",
          },
        },
      }}
    >
      <Box display={"flex"} flexDirection="column" gap={2}>
        <Grid container spacing={1}>
          {OPTION.map((item) => (
            <Grid item xs={3} key={item.key}>
              <Button
                size="large"
                onClick={() => setOption(item.key as any)}
                fullWidth
                variant={option === item.key ? "contained" : "outlined"}
              >
                {item.label}
              </Button>
            </Grid>
          ))}
        </Grid>

        {option === "price_adjust" && <TablePriceAdjust />}

        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SaveIcon fontSize="large" />}
            >
              Save Change
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="inherit"
              onClick={props.onClose}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const TablePriceAdjust = () => {
  return (
    <TableContainer>
      <Scrollbars
        autoHeight
        autoHeightMin="100%"
        autoHeightMax="100%"
        style={{ width: "100%" }}
        autoHide
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <ColumnTitle sx={{ minWidth: 50 }} align="center">
                SN
              </ColumnTitle>
              <ColumnTitle filter>User</ColumnTitle>
              <ColumnTitle filter>Date</ColumnTitle>
              <ColumnTitle filter>Location</ColumnTitle>
              <ColumnTitle filter>Model</ColumnTitle>
              <ColumnTitle filter>Charge point ID</ColumnTitle>
              <ColumnTitle filter>Idling penalty</ColumnTitle>
              <ColumnTitle filter>Detail</ColumnTitle>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">1</TableCell>
              <TableCell>Wee Keong</TableCell>
              <TableCell>28/10/2024 3:57</TableCell>
              <TableCell>Ferraria Park</TableCell>
              <TableCell>PETAS - 130</TableCell>
              <TableCell>FRP - 5</TableCell>
              <TableCell>
                <Chip
                  style={{ width: "100%" }}
                  label={"Active"}
                  size="small"
                  color="error"
                />
              </TableCell>
              <TableCell>From 10:00 to 10:30(30 mins)</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Scrollbars>
    </TableContainer>
  );
};
