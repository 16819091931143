import { Box, IconButton, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default (id?: string | number) => {
  if (id)
    return (
      <Box display={"flex"} alignItems="center" gap={1}>
        <Typography>
          {id.toString().slice(0, 6)}...{id.toString().slice(-6)}
        </Typography>
        <IconButton
          aria-label="Copy"
          size="small"
          onClick={() => {
            navigator.clipboard.writeText(id?.toString() || "");
          }}
        >
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  return null;
};
