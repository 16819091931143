import React, { useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import {
  MenuItem,
  Select,
  CircularProgress,
  Box,
  useTheme,
  TextField,
} from "@mui/material";
import { throttle } from "lodash";
import { tokens } from "../../theme";
import { useDebounce } from "../../hooks/useDebounce";
import { GetListMultipleService } from "../../services/DropdownMultiple";

interface IDropdownCus {
  selectedItem?: string;
  setSelectedItem?: React.Dispatch<React.SetStateAction<string>>;
  apiList?: string;
  params?: {};
  arrData?: Array<any>;
  labelName?: string;
  name?: string;
}

const DropdownCus = (
  {
    selectedItem,
    setSelectedItem,
    apiList,
    arrData,
    labelName,
    name,
    params,
    ...field
  }: IDropdownCus,
  ref: React.LegacyRef<HTMLInputElement>,
) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { value, setValue } = useDebounce({ initialValue: "", delay: 300 });
  const [inputValue, setInputValue] = useState("");

  const fetchList = async ({ pageParam = 1 }) => {
    const { data } = await GetListMultipleService.getList(apiList!, {
      page: pageParam,
      ...params,
    });
    return data;
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery(apiList!, fetchList, {
      getNextPageParam: (lastPage, allPages) => {
        if (+lastPage?.page >= +lastPage.total) {
          return undefined;
        }
        return allPages.length + 1;
      },
    });

  const loadMoreRef = useRef<any>(null);

  const handleScroll = throttle((e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && hasNextPage) {
      fetchNextPage();
    }
  }, 300);

  useEffect(() => {
    const isEvent = loadMoreRef.current;
    if (loadMoreRef?.current) {
      loadMoreRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (isEvent) {
        isEvent.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    apiList?.length! > 0 && refetch();
  }, [value, apiList]);

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const dataValue = { ...field } as any;

  useEffect(() => {
    if (dataValue?.value?.toString()?.length > 0 || selectedItem) {
      setIsFocused(true);
    }
  }, [dataValue, selectedItem]);

  return (
    <Box position={"relative"}>
      <p
        style={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#414141" : "#FFFFFF",
        }}
        className={`label-input ${isFocused ? "focus-label" : ""}`}
      >
        {labelName}
      </p>
      <Select
        labelId="select-label"
        fullWidth
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={selectedItem ?? ""}
        onChange={(e) => {
          setSelectedItem?.(e.target.value);
        }}
        name={name}
        sx={{
          border: "1px solid #9b9b9b !important",
          height: "60px",
          borderRadius: "5px",
          backgroundColor: colors.primary.bgInput,
          ":hover": {
            outline: "none",
            border: "none",
            boxShadow: "none",
          },
        }}
        MenuProps={{
          PaperProps: {
            ref: loadMoreRef,
            style: { maxHeight: 200, width: 200 },
          },
          MenuListProps: {
            autoFocusItem: false,
          },
        }}
        {...field}
      >
        <Box paddingX={"10px"} paddingBottom={"10px"}>
          <TextField
            autoFocus
            fullWidth
            value={inputValue}
            onChange={(e) => {
              setValue(e.target.value);
              setInputValue(e.target.value);
            }}
            sx={{
              border: "1px solid #ccc",
              height: "40px",
              padding: 0,
              input: { padding: "10px" },
              borderRadius: "3px",
            }}
            onClick={(e) => e.stopPropagation()}
          />
        </Box>

        {apiList && apiList?.length > 0
          ? data?.pages?.map((page) => {
              return page?.data?.map((item: any) => (
                <MenuItem key={item._id} value={item._id} selected={false}>
                  {item.name}
                </MenuItem>
              ));
            })
          : arrData?.map((item: any) => (
              <MenuItem key={item} value={item} selected={false}>
                {item}
              </MenuItem>
            ))}
        {isFetchingNextPage && (
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress size={24} />
          </Box>
        )}
      </Select>
    </Box>
  );
};

export default React.forwardRef(DropdownCus);
