import {
  Modal as ModalMUI,
  Box,
  styled,
  IconButton,
  Typography,
  BoxProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { PropsWithChildren, ReactNode } from "react";

export const Modal = ({
  name,
  icon,
  description,
  open,
  onClose,
  contentStyle,
  ...props
}: {
  open: boolean;
  onClose: () => void;
  name: string;
  icon?: ReactNode;
  description: string;
  contentStyle?: BoxProps;
} & PropsWithChildren) => {
  return (
    <ModalMUI
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      open={open}
      onClose={onClose}
      {...props}
    >
      <Content
        height={"fit-content"}
        sx={{
          width: {
            lg: "50%",
            md: "70%",
            xs: "90%",
          },
          maxWidth: {
            lg: "800px",
          },
          p: {
            xs: "30px",
          },
          pt: {
            xs: "28px",
          },
        }}
        {...contentStyle}
      >
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
          mb={"20px"}
        >
          <Box display="flex" alignItems={"center"} gap={1}>
            {icon ?? (
              <PersonOffIcon fontSize="large" style={{ color: "yellow" }} />
            )}
            <Box>
              <Typography fontWeight={"bold"} sx={{ fontSize: "25px" }}>
                {name}
              </Typography>
              <Typography sx={{ fontSize: "10px" }}>{description}</Typography>
            </Box>
          </Box>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        {props.children}
      </Content>
    </ModalMUI>
  );
};

const Content = styled(Box)({
  background: "#0D0D0D",
  borderRadius: "20px",
});
