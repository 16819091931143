import {
  Box,
  Button,
  Grid,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { IconApp, Modal } from "../../";
import SaveIcon from "@mui/icons-material/Save";
import SellIcon from "@mui/icons-material/Sell";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { ReactNode, useState } from "react";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import BoltIcon from "@mui/icons-material/Bolt";
import { useUpdateEvse } from "../../../services/evse";

export const ModalPriceAdjustment = (props: {
  open: boolean;
  onClose: () => void;
  init?: IEvse;
  success: () => void;
}) => {
  const { mutateAsync } = useUpdateEvse();

  const [valueUpdate, setValueUpdate] = useState<{
    price_normal: number;
    price_vip: number;
  }>({ price_vip: 0, price_normal: 0 });

  const handleUpdate = async () => {
    try {
      const result = await mutateAsync({
        id: props.init?._id!,
        dto: { /* ...props.init, */ ...valueUpdate },
      });
      if (result) {
        props.success();
        props.onClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <Modal
      {...props}
      name="Price adjustment"
      description="Adjust pricing strategies to optimize revenue"
      icon={<IconApp width={"30px"} name={"Cash"} />}
    >
      <Box display={"flex"} flexDirection="column" gap={2}>
        <Grid container bgcolor={"#1C1C1C"} borderRadius="12px" p={"18px 21px"}>
          <Grid item xs={3} borderRight="1px solid rgba(255, 255, 255, 0.12)">
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Make & Model
            </Text>
            <TextName>{props.init?.model || ""}</TextName>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Type
            </Text>
            <Box display={"flex"} gap={2} alignItems="center">
              <IconApp
                name={
                  props.init?.current_type === "ac" ? "AcCharger" : "DcCharger"
                }
                width="25px"
              />
              <TextName>{props.init?.max_p || 0} kW</TextName>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Location ID
            </Text>
            <TextName>{props.init?.Location?.name}</TextName>
          </Grid>
          <Grid item xs={3} pl={1}>
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Charge Point ID
            </Text>
            <TextName>{props.init?.ampeco_evse_id}</TextName>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <BoxPrice
            icon={<SellIcon />}
            name="Public Pricing"
            des="(Applies to Unrestricted Users)"
            callback={(e) => {
              setValueUpdate({ ...valueUpdate, price_normal: e });
            }}
            currentPrice={props.init?.price_normal || 0}
          />
          <BoxPrice
            icon={<PersonOffIcon />}
            name="Restricted Pricing"
            des="(Applies to Restricted Users)"
            callback={(e) => {
              setValueUpdate({ ...valueUpdate, price_vip: e });
            }}
            currentPrice={props.init?.price_vip || 0}
          />
        </Grid>

        <Box display={"flex"} gap={0.5} alignItems="center">
          <BoltIcon fontSize="medium" />
          <Typography fontSize={"14px"}>
            Electricity Tariff Rate
            <span>(Cost Of Electricity)</span>
          </Typography>
        </Box>

        <Box display={"flex"} alignItems="center" gap={2}>
          <Typography fontSize={"15px"}>Current Price</Typography>
          <Typography fontSize={"15px"}>
            S$ {props.init?.tariff || 0}
          </Typography>
        </Box>

        <Box display={"flex"} gap={1} alignItems="center">
          <PriorityHighIcon fontSize="small" />
          <Typography fontSize={"10px"}>
            To make changes to electricity tariff, please contact your
            relationship offcier
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SaveIcon fontSize="large" />}
              onClick={handleUpdate}
            >
              Save Change
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={props.onClose}
              color="inherit"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const Text = styled(Typography)({ color: "#9B9B9B" });
const TextName = styled(Typography)({
  fontSize: "17px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const BoxPrice = (props: {
  name: string;
  icon: ReactNode;
  des: string;
  currentPrice: number;
  callback: (e: number) => void;
}) => {
  return (
    <Grid item xs={12} md={6} display="flex" flexDirection={"column"} gap={1}>
      <Box display={"flex"} gap={1} alignItems="center">
        {props.icon}
        <Typography fontSize={"14px"}>
          {props.name} <span style={{ fontSize: "10px" }}>{props.des}</span>
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography fontSize={"15px"}>Current Price</Typography>
        <Typography fontSize={"15px"}> S$ {props.currentPrice}</Typography>
      </Box>
      <Box>
        <Typography fontSize={"15px"} mb={1}>
          New
        </Typography>
        <TextField
          fullWidth
          // size="small"
          autoComplete="off"
          placeholder="Input Your Values"
          InputProps={{
            endAdornment: <InputAdornment position="end">S$</InputAdornment>,
          }}
          type="number"
          onChange={(e) => props.callback(+e.target.value)}
        />
      </Box>
    </Grid>
  );
};
