import { BoxProps, Box, Grid } from "@mui/material";
import { ReactNode, useMemo } from "react";
import {
  AcCharger,
  IdlingPenalty,
  DcCharger,
  Cash,
  Setting,
  Location,
  Trophy,
  InfoTriangle,
  InfoCricle,
  NotAvailable,
  History,
  Battery,
  Gas,
  Timer,
  Disconnect,
  Wifi,
  User,
  Success,
} from "../../assets/icon";

export type IconType =
  | "AcCharger"
  | "Trophy"
  | "Cash"
  | "Setting"
  | "Location"
  | "DcCharger"
  | "IdLingPenalty"
  | "InfoTriangle"
  | "Disconnect"
  | "InfoCricle"
  | "NotAvailable"
  | "Battery"
  | "User"
  | "Gas"
  | "History"
  | "Timer"
  | "Success"
  | "Wifi";

type IconAppProps = {
  name: IconType;
  color?: string;
} & BoxProps;

const ListIcons: Record<IconType, (props: { color?: string }) => ReactNode> = {
  AcCharger: (props) => <AcCharger {...props} />,
  Trophy: (props) => <Trophy {...props} />,
  Cash: (props) => <Cash {...props} />,
  Setting: (props) => <Setting {...props} />,
  Location: (props) => <Location {...props} />,
  DcCharger: (props) => <DcCharger {...props} />,
  IdLingPenalty: (props) => <IdlingPenalty {...props} />,
  InfoTriangle: (props) => <InfoTriangle {...props} />,
  InfoCricle: (props) => <InfoCricle {...props} />,
  NotAvailable: (props) => <NotAvailable {...props} />,
  History: (props) => <History {...props} />,
  Battery: (props) => <Battery {...props} />,
  Gas: (props) => <Gas {...props} />,
  Timer: (props) => <Timer {...props} />,
  Wifi: (props) => <Wifi {...props} />,
  Disconnect: (props) => <Disconnect {...props} />,
  User: (props) => <User {...props} />,
  Success: (props) => <Success {...props} />,
};

//display data

export const IconApp = (props: IconAppProps) => {
  const { name, color, ...restProps } = props;
  const IconComponent = useMemo(() => ListIcons[name], [name]);
  return (
    <Box display={"flex"} height="100%" alignSelf={"center"} {...restProps}>
      {IconComponent && IconComponent({ color: color })}
    </Box>
  );
};

export const AllIcon = () => {
  return (
    <Grid container spacing={2}>
      {Object.keys(ListIcons).map((icon) => {
        return (
          <Grid item xs={6}>
            <Box display={"flex"} alignItems="center" gap={2}>
              <IconApp name={icon as IconType} width={30} />
              <Box>{icon}</Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
