import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import React from "react";

interface IIconAction {
  title: string;
  icon: React.ReactNode;
}

const IconAction = ({
  title,
  icon,
  ...iconButtonProps
}: IIconAction & IconButtonProps) => {
  return (
    <Tooltip title={title}>
      <IconButton {...iconButtonProps}>{icon}</IconButton>
    </Tooltip>
  );
};

export default IconAction;
