import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { IconApp, Modal } from "../../";
import SaveIcon from "@mui/icons-material/Save";
import { ChargePointService } from "../../../services/charge-points/charge-point.service";
import { useInfiniteQuery } from "react-query";
import { useLayoutEffect, useMemo, useState } from "react";
import { SubscriptionsPlansService } from "../../../services/subscriptions-plans/subscriptions-plans.service";
import { useEnableSubscriptionPlans as useEnableSubscriptionPlansLocation } from "../../../services/locations/useLocation";
import { useEnableSubscriptionPlans as useEnableSubscriptionPlansChargepoint } from "../../../services/charge-points/useChargePoint";

type IOption = {
  _id: string;
  label: string;
};

export const ModalEnableSubscriptionPlans = (props: {
  open: boolean;
  onClose: () => void;
  location_id?: string;
  init_chargePoints?: IOption[];
  init_chargePoint?: IOption;
  init_enable_subscription_plans?: string[];
  callback?: () => void;
}) => {
  //init data
  const [chargePoints, setChargepoints] = useState<IOption[]>([]);
  const [chargePoint, setChargepoint] = useState<IOption>();
  const [subscriptionPlans, setSubscription] = useState<IOption[]>([]);

  const multi = useMemo(() => {
    return props.location_id ? true : false;
  }, [props.location_id]);

  const { data, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ["infiniti_chargepoint", props.location_id],
    enabled: props.open && multi,
    queryFn: ({ pageParam = 1 }) =>
      fetchChargepoint({ pageParam, location_id: props.location_id }),
    getNextPageParam: (currentPage, allPage) => {
      const check =
        (currentPage?.per_page || 0) * (currentPage?.page || 0) <
        (currentPage?.total || 0);
      return check ? allPage.length + 1 : undefined;
    },
  });

  const listChargePoints: Array<IOption> = useMemo(() => {
    const arr = data?.pages.map((page) => {
      return (
        page?.data.map((item) => ({
          _id: item._id,
          label: item.name || "Unknown",
        })) || []
      );
    });
    return arr?.flat() || [];
  }, [data?.pages]);

  const {
    data: listSubscription,
    hasNextPage: hasNextPageSub,
    fetchNextPage: fetchNextPageSub,
  } = useInfiniteQuery({
    queryKey: ["infiniti_subscription"],
    enabled: props.open,
    queryFn: ({ pageParam = 1 }) => fetchSubscription({ pageParam }),
    getNextPageParam: (currentPage, allPage) => {
      const check =
        (currentPage?.per_page || 0) * (currentPage?.page || 0) <
        (currentPage?.total || 0);
      return check ? allPage.length + 1 : undefined;
    },
  });

  const listSubscrip: Array<IOption> = useMemo(() => {
    const arr = listSubscription?.pages.map((page) => {
      return (
        page?.data.map((item: { _id: string; name: string }) => ({
          _id: item._id,
          label: item.name || "Unknown",
        })) || []
      );
    });
    return arr?.flat() || [];
  }, [listSubscription?.pages]);

  useLayoutEffect(() => {
    if (props.open) {
      setChargepoints(props.init_chargePoints || []);
      setChargepoint(props?.init_chargePoint);
    }
  }, [props.open]);

  useLayoutEffect(() => {
    if (listSubscrip.length > 0 && props.init_enable_subscription_plans) {
      setSubscription(
        props.init_enable_subscription_plans.map((i) => ({
          _id: i,
          label: listSubscrip.find((e) => e._id === i)?.label || i,
        })),
      );
    } else {
      setSubscription([]);
    }
  }, [listSubscrip, props.init_enable_subscription_plans]);

  const handleClose = () => {
    props.onClose();
    setSubscription([]);
    setChargepoint(undefined);
    setChargepoints([]);
  };

  const { mutateAsync: mutateAsyncLocation } =
    useEnableSubscriptionPlansLocation();
  const { mutateAsync: mutateAsyncChargepoint } =
    useEnableSubscriptionPlansChargepoint();

  const handleUpdate = async () => {
    try {
      if (multi) {
        await mutateAsyncLocation({
          charge_point_ids: chargePoints.map((item) => item._id),
          subscription_plans: subscriptionPlans.map((item) => item._id),
        });
      } else {
        await mutateAsyncChargepoint({
          charge_point_id: chargePoint?._id,
          subscription_plans: subscriptionPlans.map((item) => item._id),
        });
      }
      handleClose();
      props?.callback?.();
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <Modal
      {...props}
      onClose={handleClose}
      name="Enable Subscription plans"
      description="View & edit your charge point’s pricing"
      icon={<IconApp width={"27px"} name={"IdLingPenalty"} />}
    >
      <Box display={"flex"} flexDirection="column" gap={2}>
        <Box display={"flex"} flexDirection="column" gap={1}>
          <Typography>Charge point</Typography>

          {multi ? (
            <Autocomplete
              multiple
              limitTags={4}
              disableCloseOnSelect
              options={listChargePoints}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              onChange={(_, value) => {
                setChargepoints(value);
              }}
              value={chargePoints}
              ListboxProps={{
                onScroll: (event: React.SyntheticEvent) => {
                  const listboxNode = event.currentTarget;
                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight ===
                    listboxNode.scrollHeight
                  ) {
                    hasNextPage && fetchNextPage();
                  }
                },
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Charge point" />
              )}
              fullWidth
            />
          ) : (
            <Autocomplete
              options={multi ? listChargePoints : []}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              onChange={(_, value) => {
                if (value) setChargepoint(value);
              }}
              value={chargePoint}
              ListboxProps={{
                onScroll: (event: React.SyntheticEvent) => {
                  const listboxNode = event.currentTarget;
                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight ===
                    listboxNode.scrollHeight
                  ) {
                    hasNextPage && fetchNextPage();
                  }
                },
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Charge point" />
              )}
              fullWidth
            />
          )}
        </Box>

        <Box display={"flex"} flexDirection="column" gap={1}>
          <Typography>Subscription plans</Typography>
          <Autocomplete
            multiple
            limitTags={4}
            disableCloseOnSelect
            options={listSubscrip}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            onChange={(_, value) => {
              setSubscription(value);
            }}
            value={subscriptionPlans}
            ListboxProps={{
              onScroll: (event: React.SyntheticEvent) => {
                const listboxNode = event.currentTarget;
                if (
                  listboxNode.scrollTop + listboxNode.clientHeight ===
                  listboxNode.scrollHeight
                ) {
                  hasNextPageSub && fetchNextPageSub();
                }
              },
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Subscription plans" />
            )}
            fullWidth
          />
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SaveIcon fontSize="large" />}
              onClick={handleUpdate}
            >
              Save Change
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="inherit"
              onClick={handleClose}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const fetchChargepoint = async ({
  pageParam,
  location_id,
}: {
  pageParam: number;
  location_id?: string;
}) => {
  try {
    const { data } = await ChargePointService.getListChargePoint({
      page: pageParam,
      per_page: 15,
      location_id: location_id,
    });
    return data;
  } catch (error) {
    console.log("error", error);
  }
};

const fetchSubscription = async ({ pageParam }: { pageParam: number }) => {
  try {
    const { data } = await SubscriptionsPlansService.getListSubscriptionsPlans({
      page: pageParam,
      per_page: 100,
    });
    return data;
  } catch (error) {
    console.log("error", error);
  }
};
