export default ({ color }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 36" fill="none">
      <path
        d="M35 31.125H4.375V28.9375H32.8125V13.625H35V31.125Z"
        fill={color ?? "#FFFF00"}
      />
      <path
        d="M28.4375 9.25V24.5625H2.1875V9.25H28.4375ZM30.625 7.0625H0V26.75H30.625V7.0625Z"
        fill={color ?? "#FFFF00"}
      />
      <path
        d="M6.5625 13.625H4.375V20.1875H6.5625V22.375H15.3125C13.8621 22.375 12.4711 21.7988 11.4455 20.7732C10.4199 19.7477 9.84375 18.3567 9.84375 16.9062C9.84375 15.4558 10.4199 14.0648 11.4455 13.0393C12.4711 12.0137 13.8621 11.4375 15.3125 11.4375H6.5625V13.625ZM24.0625 13.625V11.4375H15.3125C16.7629 11.4375 18.1539 12.0137 19.1795 13.0393C20.2051 14.0648 20.7812 15.4558 20.7812 16.9062C20.7812 18.3567 20.2051 19.7477 19.1795 20.7732C18.1539 21.7988 16.7629 22.375 15.3125 22.375H24.0625V20.1875H26.25V13.625H24.0625Z"
        fill={color ?? "#FFFF00"}
      />
    </svg>
  );
};
