import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { useInfoMe } from "../services/auth/useLogin";
import { getLocalItem } from "../utils/localStorage";

interface Profile {
  name: string;
  email: string;
}

interface RolePermission {
  Admin: Array<string>;
  AdminNotification: Array<string>;
  ChargePoint: Array<string>;
  ChargeSession: Array<string>;
  Company: Array<string>;
  Deal: Array<string>;
  Evse: Array<string>;
  FAQ: Array<string>;
  Information: Array<string>;
  InformationCategory: Array<string>;
  Location: Array<string>;
  Notification: Array<string>;
  Payout: Array<string>;
  Report: Array<string>;
  Role: Array<string>;
  TokenizedCard: Array<string>;
  Transaction: Array<string>;
  Subscription: Array<string>;
  SubscriptionPlan: Array<string>;
  PromoCode: Array<string>;
  PromoProgram: string[];
  User: Array<string>;
}

interface CommonContextType {
  profile: Profile | null;
  setProfile: (profile: Profile) => void;
  rolePermissions: RolePermission | null;
}

const CommonContext = createContext<CommonContextType | undefined>(undefined);

interface CommonProviderProps {
  children: ReactNode;
}

export const CommonProvider: React.FC<CommonProviderProps> = ({ children }) => {
  const [token, setToken] = useState(getLocalItem("token"));

  const [profile, setProfile] = useState<Profile | null>(null);
  const [rolePermissions, setRolePermissions] = useState<RolePermission | null>(
    null,
  );

  window.addEventListener("storage", (e: any) => {
    const local = e.currentTarget.localStorage;
    if (local.token) {
      setToken(local.token);
    }
  });

  const { data } = useInfoMe(token);

  useEffect(() => {
    if (data) {
      setProfile({
        name: data.data?.name,
        email: data.data?.email,
      });
      setRolePermissions(data?.data?.Role);
    }
  }, [data]);

  return (
    <CommonContext.Provider value={{ profile, setProfile, rolePermissions }}>
      {children}
    </CommonContext.Provider>
  );
};

export const usePermission = () => {
  const context = useContext(CommonContext);
  if (context === undefined) {
    throw new Error("useCommon must be used within a CommonProvider");
  }
  return {
    dashboard: ["read"],
    admin: context?.rolePermissions?.Admin,
    adminNotification: context?.rolePermissions?.AdminNotification,
    chargePoint: context?.rolePermissions?.ChargePoint,
    chargeSession: context?.rolePermissions?.ChargeSession,
    company: context?.rolePermissions?.Company,
    deal: context?.rolePermissions?.Deal,
    evse: context?.rolePermissions?.Evse,
    fAQ: context?.rolePermissions?.FAQ,
    information: context?.rolePermissions?.Information,
    informationCategory: context?.rolePermissions?.InformationCategory,
    location: context?.rolePermissions?.Location,
    notification: context?.rolePermissions?.Notification,
    payout: context?.rolePermissions?.Payout,
    report: context?.rolePermissions?.Report,
    role: context?.rolePermissions?.Role,
    tokenizedCard: context?.rolePermissions?.TokenizedCard,
    transaction: context?.rolePermissions?.Transaction,
    subscription: context?.rolePermissions?.Subscription,
    subscriptionplan: context?.rolePermissions?.SubscriptionPlan,
    promoCode: context?.rolePermissions?.PromoCode,
    promoprogram: context?.rolePermissions?.PromoProgram,
    user: context?.rolePermissions?.User,
    profile: context?.profile,
  };
};
