export default ({ color }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 17" fill="none">
      <path
        d="M6.03548 1.88867C5.70756 1.88867 5.44173 1.62284 5.44173 1.29492C5.44173 0.967003 5.70756 0.701172 6.03548 0.701172H8.83444C9.16236 0.701172 9.42819 0.967003 9.42819 1.29492C9.42819 1.62284 9.16236 1.88867 8.83444 1.88867H6.03548Z"
        fill={color ?? "#C7C7C7"}
      />
      <path
        d="M3.28866 2.24643C3.52053 2.4783 3.52053 2.85424 3.28866 3.08612L1.30949 5.06529C1.07762 5.29716 0.701678 5.29716 0.469804 5.06529C0.23793 4.83341 0.23793 4.45747 0.469804 4.2256L2.44897 2.24643C2.68084 2.01456 3.05679 2.01456 3.28866 2.24643Z"
        fill={color ?? "#C7C7C7"}
      />
      <path
        d="M7.61882 4.05169C4.44893 4.05169 1.87923 6.62139 1.87923 9.79128C1.87923 12.9612 4.44893 15.5309 7.61882 15.5309C10.7887 15.5309 13.3584 12.9612 13.3584 9.79128C13.3584 9.46336 13.6242 9.19753 13.9521 9.19753C14.2801 9.19753 14.5459 9.46336 14.5459 9.79128C14.5459 13.617 11.4445 16.7184 7.61882 16.7184C3.79309 16.7184 0.691732 13.617 0.691732 9.79128C0.691732 5.96555 3.79309 2.86419 7.61882 2.86419C7.94673 2.86419 8.21257 3.13002 8.21257 3.45794C8.21257 3.78586 7.94673 4.05169 7.61882 4.05169Z"
        fill={color ?? "#C7C7C7"}
      />
      <path
        d="M11.7261 6.12131C11.91 5.88495 11.889 5.54868 11.6773 5.33694C11.4656 5.1252 11.1293 5.10427 10.8929 5.28811L8.38343 7.23995L6.74356 8.41129C6.39327 8.66149 6.18537 9.06547 6.18537 9.49594C6.18537 10.2321 6.78215 10.8289 7.51831 10.8289C7.94879 10.8289 8.35276 10.621 8.60297 10.2707L9.77431 8.63082L11.7261 6.12131Z"
        fill={color ?? "#C7C7C7"}
      />
    </svg>
  );
};
