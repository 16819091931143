import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IconApp } from "../Icon";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";

export const ModalConfirmDelete = (props: {
  open: boolean;
  onClose: () => void;
  confirm: () => void;
  subTitle?: string;
}) => {
  return (
    <Modal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...props}
    >
      <Content
        sx={{
          width: {
            md: "50%",
            xs: "90%",
          },
          maxWidth: {
            lg: "600px",
          },
          p: {
            xs: "30px",
          },
          pt: {
            xs: "28px",
          },
        }}
      >
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Box width={35} height={35} />
          <IconApp name="NotAvailable" width={35} height={35} />
          <IconButton onClick={props.onClose} size="small">
            <CloseIcon color="warning" fontSize="large" />
          </IconButton>
        </Box>
        <Box
          display={"flex"}
          flexDirection="column"
          alignItems={"center"}
          gap={1}
        >
          <Typography
            width={"50%"}
            fontWeight="bold"
            fontSize={20}
            textAlign="center"
          >
            Are you sure you want to delete?
          </Typography>
          <Typography
            width={"50%"}
            color="#9B9B9B"
            fontSize={10}
            textAlign="center"
          >
            {props.subTitle ?? "View & edit your charge point’s pricing"}
          </Typography>
        </Box>

        <Grid container spacing={1} mt={2}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<TaskAltRoundedIcon fontSize="large" />}
              onClick={props.confirm}
            >
              Confirm
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="text"
              onClick={props.onClose}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Content>
    </Modal>
  );
};
const Content = styled(Box)({
  background: "#0D0D0D",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});
