export default ({ color }: { color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
      <path
        d="M11 6.99999C11 7.55227 10.5523 7.99999 10 7.99999C9.44771 7.99999 9 7.55227 9 6.99999C9 6.4477 9.44771 5.99999 10 5.99999C10.5523 5.99999 11 6.4477 11 6.99999Z"
        fill={color ?? "black"}
      />
      <path
        d="M10 9.74999C10.4142 9.74999 10.75 10.0858 10.75 10.5V15.5C10.75 15.9142 10.4142 16.25 10 16.25C9.58579 16.25 9.25 15.9142 9.25 15.5V10.5C9.25 10.0858 9.58579 9.74999 10 9.74999Z"
        fill={color ?? "black"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2703 1.99262C11.1784 0.394959 8.82159 0.394953 7.72961 1.99262L7.29769 2.62457C4.59165 6.58378 2.26669 10.7904 0.353957 15.1881L0.263742 15.3955C-0.410313 16.9453 0.608051 18.705 2.2876 18.8927C7.41323 19.4656 12.5867 19.4656 17.7124 18.8927C19.3919 18.705 20.4103 16.9453 19.7362 15.3955L19.646 15.1881C17.7333 10.7904 15.4083 6.58379 12.7023 2.62457L12.2703 1.99262ZM8.96799 2.83903C9.46434 2.11282 10.5356 2.11282 11.032 2.83903L11.4639 3.47098C14.1165 7.352 16.3955 11.4755 18.2705 15.7863L18.3607 15.9938C18.6321 16.6178 18.222 17.3264 17.5458 17.402C12.5309 17.9625 7.46911 17.9625 2.4542 17.402C1.77791 17.3264 1.36785 16.6178 1.63927 15.9938L1.72948 15.7863C3.60443 11.4755 5.88348 7.35199 8.53607 3.47098L8.96799 2.83903Z"
        fill={color ?? "black"}
      />
    </svg>
  );
};
