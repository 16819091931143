import { memo, useContext, useMemo, useState } from "react";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import {
  Box,
  styled,
  Typography as TypoDefault,
  useTheme,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ColorModeContext, tokens } from "../../theme";
import {
  ChargerIcon,
  DashboardIcon,
  IdlingIcon,
  LogoutIcon,
  ReportIcon,
  RestrictedIcon,
  TransactionsIcon,
} from "../../assets/icons/sidebar";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InfoIcon from "@mui/icons-material/Info";
import CategoryIcon from "@mui/icons-material/Category";
import { Logo, LogoDark } from "../../assets/icons";
import { ROUTES } from "../../routers";
import { Scrollbars } from "react-custom-scrollbars-2";
import { usePermission } from "../../hooks/CommonContext";
import { EAction } from "../../types/enum";
import LocalGasStationOutlinedIcon from "@mui/icons-material/LocalGasStationOutlined";
import { AvtImg } from "../../assets/images";
import { IconApp } from "../../components";

export const ArrMenuSideBar = [
  {
    title: "Dashboard",
    to: ROUTES.DASHBOARD,
    icon: <DashboardIcon />,
    role: "dashboard",
  },
  {
    title: "Charger point (EVSE)",
    to: ROUTES.CHARGER_POINT,
    icon: <LocalGasStationOutlinedIcon />,
    role: "chargePoint",
  },
  {
    title: "Charging Session",
    to: ROUTES.CHARGE_SESSION,
    icon: <ChargerIcon />,
    role: "chargeSession",
  },
  {
    title: "Transactions",
    to: ROUTES.TRANSACTION,
    icon: <TransactionsIcon />,
    role: "transaction",
  },
  {
    title: "Location",
    to: ROUTES.LOCATION,
    icon: <LocationOnIcon />,
    role: "location",
  },
  {
    title: "Idling Activities",
    to: ROUTES.IDLING_ACTIVITIES,
    icon: <IdlingIcon />,
    role: "chargeSession",
  },
  {
    title: "Charge Point",
    to: ROUTES.EVSE,
    icon: <ChargerIcon />,
    role: "evse",
  },
  {
    title: "Information",
    to: ROUTES.INFORMATION,
    icon: <InfoIcon />,
    role: "information",
  },
  {
    title: "Information Category",
    to: ROUTES.INFORMATION_CATEGORY,
    icon: <CategoryIcon />,
    role: "informationCategory",
  },

  {
    title: "Reservation",
    to: ROUTES.RESERVATION,
    icon: <IdlingIcon />,
    role: "chargeSession",
  },
  {
    title: "User",
    to: "/user",
    icon: <RestrictedIcon />,
    role: "user",
  },
  {
    title: "Restricted User",
    to: "/user/restricted-user",
    icon: <RestrictedIcon />,
    role: "evse",
  },
  {
    title: "Promo Code",
    to: "/promo-codes",
    icon: <ChargerIcon />,
    role: "promoCode",
  },
  {
    title: "Promo Program",
    to: "/promo-programs",
    icon: <ChargerIcon />,
    role: "promoprogram",
  },
  {
    title: "Subscription Plans",
    to: "/subscription-plans",
    icon: <ReportIcon />,
    role: "subscriptionplan",
  },
  {
    title: "Subscriptions",
    to: "/subscriptions",
    icon: <ReportIcon />,
    role: "subscription",
  },
  {
    title: "Reports",
    to: ROUTES.REPORTS,
    icon: <ReportIcon />,
    role: "report",
  },
];

export const Item = memo(
  ({
    title,
    to,
    icon,
    location,
    isCollapsed,
    roleName,
  }: {
    title: string;
    to: string;
    icon: React.ReactNode;
    location: string;
    isCollapsed?: boolean;
    roleName?: string;
  }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isActive = location === to;
    const role = useCheck(roleName);

    return ((role as Array<string>) || []).includes(EAction.READ) ? (
      <MenuItem
        style={{
          backgroundColor: isActive && isCollapsed ? "#c7c7c7" : "unset",
          display: "flex",
          marginLeft: isActive ? "-10px" : 0,
        }}
        component={<Link to={to} />}
        icon={isActive ? icon : undefined}
      >
        <Typography
          color={isActive ? colors.primary["activeText"] : "#c7c7c7"}
          fontSize={"16px"}
          fontWeight={isActive ? "700" : "300"}
        >
          {title}
        </Typography>
      </MenuItem>
    ) : null;
  },
);

// const useStyles = makeStyles<Record<string, any>>({
//   btnToggleDarkMode: {
//     outline: "none",
//     border: "none",
//     background: "inherit",
//     cursor: "pointer",
//     display: "flex",
//     alignItem: "center",
//     // position: "absolute",
//     // top: "10px",
//     // right: "23px",
//   },
// });

const SidebarLayout = () => {
  const [isCollapsed] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const colorMode = useContext(ColorModeContext);
  // const classes = useStyles() as any;
  const navigate = useNavigate();
  const { profile } = usePermission();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
    navigate(ROUTES.LOGIN);
  };

  return (
    <Box
      position={"relative"}
      sx={{
        borderRadius: {
          xs: "0px",
          md: "20px",
        },
      }}
      overflow={"hidden"}
      width="270px"
    >
      <Sidebar
        rootStyles={{
          height: "100%",
          paddingRight: "0px",
          [`.${sidebarClasses.container}`]: {
            backgroundColor: colors.primary["bg"],
            // padding: "50px 20px 15px",
            display: !isCollapsed ? "flex" : "block",
            width: "270px",
          },
        }}
        collapsed={isCollapsed}
      >
        <Menu
          rootStyles={{
            height: "100%",
            width: "100%",
            [`ul`]: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "15px",
              height: "100%",
            },
          }}
          menuItemStyles={{
            button: {
              paddingLeft: "40px",
              borderRadius: "5px",
            },
          }}
        >
          <Box>
            <Box
              display={"flex"}
              justifyContent="space-between"
              width={"100%"}
              p="50px 20px 30px 40px"
              alignItems="center"
            >
              <img
                onClick={() => navigate(ROUTES.DASHBOARD)}
                src={theme.palette.mode === "light" ? Logo : LogoDark}
                alt="Logo"
                style={{
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box display={"flex"} gap={2} p="40px" alignItems={"start"}>
              <IconApp name={"User"} width={35} height={35} />
              {/* <img */}
              {/*   src={AvtImg} */}
              {/*   style={{ */}
              {/*     minWidth: "40px", */}
              {/*     maxWidth: "40px", */}
              {/*     height: "40px", */}
              {/*     borderRadius: "6px", */}
              {/*     overflow: "hidden", */}
              {/*   }} */}
              {/* /> */}
              <Box display="flex" flexDirection={"column"} overflow="hidden">
                <Typography fontSize={"15px"} fontWeight={"700"}>
                  {profile?.name}
                </Typography>
                <Typography fontSize={"11px"} color="#c7c7c7">
                  {profile?.email}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box flex={1}>
            <Scrollbars style={{ height: "100%" }} autoHide>
              {ArrMenuSideBar.map((item, index) => (
                <Item
                  key={index}
                  title={item.title}
                  to={item.to}
                  icon={item.icon}
                  location={location.pathname}
                  isCollapsed={isCollapsed}
                  roleName={item.role}
                />
              ))}
            </Scrollbars>
          </Box>
          <Box
            onClick={handleLogout}
            // paddingLeft={!isCollapsed ? "15px" : "0px"}
            p={"0px 0px 40px 40px"}
            // height={"48px"}
            display={"flex"}
            alignItems={"center"}
            gap={"20px"}
            style={{ cursor: "pointer" }}
          >
            <LogoutIcon color={colors.primary.activeText} />
            {!isCollapsed && (
              <Typography
                color={"#c7c7c7"}
                fontSize={"16px"}
                // fontWeight={"700"}
              >
                Log out
              </Typography>
            )}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default memo(SidebarLayout);

const useCheck = (role?: string) => {
  const roleDefault = usePermission();

  const permission = useMemo(() => {
    return (roleDefault as any)[role || ""];
  }, [role, roleDefault]);

  return permission;
};

const Typography = styled(TypoDefault)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
