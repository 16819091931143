import { Box, Typography } from "@mui/material";

export default (text: Array<string | number>) => {
  return (
    <Box display="flex" flexDirection={"column"}>
      {text.map((item, index) => (
        <Typography key={index}>{item}</Typography>
      ))}
    </Box>
  );
};
